exports.onRouteUpdate = ({location}) => {
  anchorScroll(location);
  return true;
};

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  anchorScroll(location);
  return true;
};

function anchorScroll(location) {
  // Check for location so build does not fail
  if (location && location.hash) {
    setTimeout(() => {
      if (/^[#]{2,}(.+)/.test(location.hash)) return;
      const item = document.querySelector(`${location.hash}`);
      if (!item) return;
      window.scrollTo({top: item.offsetTop, left: 0, behavior: 'smooth'});
    }, 0);
  }
}
